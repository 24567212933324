<template>
  <div />
</template>

<script>

export default {
  data() {
    return {}
  },
  created() {

  },
  mounted() {
    localStorage.clear()
    sessionStorage.clear()
    alert('缓存清空')
  },
  methods: {}
}
</script>

<style lang="less" scoped>

</style>
